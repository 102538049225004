import { useTheme } from 'styled-components';
import {
    ModalContainer,
    Title,
} from './styled';
import TModal from '../TModal';
import { FormButton } from '../FormButton';
import { useEffect, useRef, useState } from 'react';
import CloseButton from '../CloseButton';
import { Form, Row } from 'react-bootstrap';
import { EventResponse, IParticipantSearchBy } from '@/Services/api/models/eventModel';
import ApiService from '@/Services/api/ApiService';
import { handlePrintAppConfig, handlePrintTicket } from '@/util/print';
import { CheckInForm, CheckoutButton, FormLabel } from '@/Pages/AutoAttendant/CheckIn/styled';
import { GetParticipantResponse } from '@/Services/api/models/participantsModel';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import ModalLoading from '../ModalLoading';


interface Props {
    event: EventResponse
    hideModal: () => void;
    isActive: boolean;
    participantList: GetParticipantResponse[];
}

interface CheckInFormValues {
    document: string;
}

export default function ModalAttendant({
    event,
    hideModal,
    isActive,
    participantList
}: Props) {
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const { register, handleSubmit, setFocus, setValue, watch, getValues } = useForm<CheckInFormValues>();
    
    const onConfirmCheckout = async (participantFound) => {
        try {
            setIsLoading(true);
            if (!participantFound) {
                toast.error('Participante não encontrado')
                return
            }
        
            const d = new Date();
            d.setHours(d.getHours() - 3);
            if(participantFound.documentNumber !== '00000000000') {
                await ApiService.checkInParticipant(participantFound.id, d.toISOString(), 0)
            }
            if(event.labelConfiguration !== 'No label') { await handlePrintTicket(event, participantFound) };
            toast.success('Check-in realizado')
        } catch (e: any) {
            if((e?.response?.data?.message)?.toString()?.includes('CheckIn já realizado para o participante')) {
                toast.error('Check-in já realizado')
            }
            else {
                toast.error(e.message)
            }
        }
    };

    const userFilterDicionary = (
        participants: GetParticipantResponse[],
        valueToSearch: string,
    ): GetParticipantResponse | -1 => {
        let participantFound: GetParticipantResponse;

        const formatedValue = valueToSearch.toLocaleLowerCase().trim();
        
        const participantsFound = participants?.filter((p) => p?.checkInQrCode?.toLowerCase() === formatedValue)
        if(participantsFound && participantsFound.length === 1) {
            participantFound = participantsFound[0]
            return participantFound
        }
    };

    useEffect(() => {
        setFocus('document')
    }, [isActive]);

    const handleSubmitForm: SubmitHandler<CheckInFormValues> = async (data) => {
        try {
            setIsLoading(true);

            if (!data.document) {
                return;
            }

            const participantFound = userFilterDicionary(
                participantList,
                data.document?.toString()?.replaceAll('Ç',':')?.replaceAll(';','/'),
            );
            if (participantFound === -1) {
                throw new Error('Não conseguimos te encontrar com o documento ' + data.document);
            }

            if (!participantFound) {
                toast.error('Participante não encontrado')
                return
            }
            if (!participantFound.active) {
                toast.warn('Participante inativo!')
                setValue('document','')
                return
            }
            if(participantFound) {
                onConfirmCheckout(participantFound)
                setValue('document','')
            }
        } catch (e: any) {
           toast.error(e?.message)
        } finally {
            setIsLoading(false);
        }
    };
    
    return (
    <>
        <TModal
            active={isActive}
            width='400px'
            height='200px'
            hideModal={() => undefined}
            footer={
                <>
                </>
            }
        >
            <ModalLoading isActive={isLoading}/>
            <ModalContainer>
                <a onClick={() => hideModal()}>
                    <CloseButton />
                </a>
                <Title>
                    Escaneie o QR CODE          
                </Title>
                <CheckInForm
                    onSubmit={handleSubmit(handleSubmitForm)}
                    className='align-self-center d-flex'
                >
                    <Form.Group as={Row} className='mb-3 align-self-center'>
                        <Form.Control
                            id='document'
                            className='input-lg'
                            {...register('document')}
                            type='text'
                            value={watch('document')}
                        />
                    </Form.Group>
                    <FormButton type='submit'> Check-in </FormButton>
                </CheckInForm>
            </ModalContainer>
        </TModal>
    </>
    );
}
