import { Background } from '@/Components/Background';
import { Header } from '@/Components/Header';
import ModalLoading from '@/Components/ModalLoading';
import ApiService from '@/Services/api/ApiService';
import { useEffect, useState } from 'react';
import {
    CapitalizedRow,
    GetEventosContainer,
    SmallerColumn,
    StatusButton,
    WhiteBoxContainer,
} from './styled';
import { ActionButtonOptions, TableHeader } from '@/Models/CrudTableModel';
import { TButton } from '@/Components/TButton';
import { Table } from 'react-bootstrap';
import { DropdownButton } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { UsersResponse } from '@/Services/api/models/usersModel';
import { IUserGroup } from '@/Services/api/models/usersModel';
import { toast } from 'react-toastify';

export function GetUsers() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [users, setUsers] = useState<UsersResponse[]>();
    const navigate = useNavigate();
    const location = useLocation();

    const handleGetAllUsers = async () => {
        try {
            setIsLoading(true);
            const response: UsersResponse[] = await ApiService.getAllUsers();
            const filteredUsers = location?.state?.event? response.filter((user) => user.events.includes(location.state.event)) : response.filter((user) => (user.userGroup === 'ADMIN' || user.userGroup === 'PARTNER_ADMIN'))
            setUsers(filteredUsers);
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const FormatedColumns: TableHeader<UsersResponse>[] = [
        {
            title: '',
            key: 'empty',
        },
        {
            title: 'Nome',
            key: 'name',
        },
        {
            title: 'Login',
            key: 'email',
        },
        {
            title: 'Grupos',
            key: 'userGroup',
        },
        {
            title: '',
            key: 'empty',
        },
    ];

    const handleEditUser = (id: number) => {
        navigate('/usuarios/editar', { state: location?.state?.event? {userId: id, event: location?.state?.event} : {userId: id} });
    };

    const handleExcludeUser = async (id: number) => {
        try {
            setIsLoading(true);
            await ApiService.DeleteUser(id);
            await handleGetAllUsers();
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const actionOptions: ActionButtonOptions<UsersResponse>[] = [
        {
            title: 'Editar',
            onClick: (user: UsersResponse) => handleEditUser(user.id),
        },
        {
            title: 'Excluir',
            onClick: (user) => handleExcludeUser(user.id),
        },
    ];

    useEffect(() => {
        handleGetAllUsers();
    }, []);

    return (
        <Background>
            <ModalLoading isActive={isLoading} />
            <GetEventosContainer>
                <Header pageTitle='Consulta de Usuários' />
                <WhiteBoxContainer>
                    <div className='header'>
                        <TButton 
                            onClick={() => navigate('/usuarios/adicionar',location?.state?.event?{state: {event: location?.state?.event}} : {})}
                        > 
                            Novo 
                        </TButton>
                        {location?.state?.event &&
                            <TButton 
                                onClick={() => navigate('/usuarios/convidar',location?.state?.event?{state: {event: location?.state?.event}} : {})}
                            > 
                                Gerar link de convite
                            </TButton>
                        }
                    </div>
                    <div className='body'></div>
                    <div className='tabela'>
                        {users && (
                            <Table hover>
                                <thead>
                                    <tr>
                                        {FormatedColumns.map((column, index) => (
                                            <th key={index}> {column.title} </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map((userMapped) => {
                                        return (
                                            <tr key={userMapped.id}>
                                                <SmallerColumn>
                                                    {' '}
                                                    <DropdownButton
                                                        variant='warning'
                                                        id={`dropdown-variants-Warning`}
                                                        title={'Ação'}
                                                        color={'white'}
                                                        className='teste'
                                                        size='sm'
                                                    >
                                                        {actionOptions.map((action) => {
                                                            return (
                                                                <Dropdown.Item
                                                                    key={action.title}
                                                                    onClick={() =>
                                                                        action.onClick(userMapped)
                                                                    }
                                                                >
                                                                    {' '}
                                                                    {action.title}{' '}
                                                                </Dropdown.Item>
                                                            );
                                                        })}
                                                    </DropdownButton>{' '}
                                                </SmallerColumn>
                                                <td> {userMapped.name} </td>
                                                <td> {userMapped.email} </td>
                                                <td>
                                                    {' '}
                                                    <CapitalizedRow>
                                                        {' '}
                                                        <>
                                                            {' '}
                                                            {IUserGroup[
                                                                userMapped.userGroup
                                                            ].toLocaleLowerCase()}{' '}
                                                        </>{' '}
                                                    </CapitalizedRow>
                                                </td>
                                                <SmallerColumn>
                                                    <StatusButton
                                                        className={
                                                            userMapped.active ? 'active' : 'inative'
                                                        }
                                                    >
                                                        {userMapped.active ? 'Ativo' : 'Inativo'}
                                                    </StatusButton>
                                                </SmallerColumn>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        )}
                    </div>
                </WhiteBoxContainer>
            </GetEventosContainer>
        </Background>
    );
}
