export default function generateZPL(labelConfig, labelResolution: string) {
    let zpl = '^XA^MM^PW639^LL320'
    
    const posTable = {
        '7x3': {
            'top': '52,52',
            'center': '52,124',
            'bottomLeft': '52,201',
            'bottomRight': '300,201',
            'bRQR': '400,230'
        },
        '8x3': {
            'top': '10,52',
            'center': '10,124',
            'bottomLeft': '10,201',
            'bottomRight': '300,201',
            'bRQR': '400,230'
        },
        '8x4': {
            'top': '10,52',
            'center': '10,135',
            'bottomLeft': '10,250',
            'bottomRight': '350,250',
            'bRQR': '450,310'
        },
        '9x4': {
            'top': '10,52',
            'center': '10,135',
            'bottomLeft': '10,250',
            'bottomRight': '350,250',
            'bRQR': '450,310'
        },
        '9x5': {
            'top': '10,52',
            'center': '10,135',
            'bottomLeft': '10,250',
            'bottomRight': '350,250',
            'bRQR': '450,310'
        },
        '10x4': {
            'top': '10,52',
            'center': '10,135',
            'bottomLeft': '10,250',
            'bottomRight': '350,250',
            'bRQR': '450,310'
        },
        '10x5': {
            'top': '10,52',
            'center': '10,135',
            'bottomLeft': '10,250',
            'bottomRight': '350,250',
            'bRQR': '450,310'
        },
        '10x6': {
            'top': '10,52',
            'center': '10,135',
            'bottomLeft': '10,250',
            'bottomRight': '350,250',
            'bRQR': '450,310'
        },
    }

    const fontSizeTable = {
        'small': '38,39',
        'large': '45,45'
    }

    const fntTable = {
        'normal': 'ARI000.FNT',
        'bold': 'ARI001.FNT'
    }

    Object?.keys(labelConfig)?.map((pos) => {
        if(labelConfig?.[pos]?.value === '[[QRCODE]]') {
            zpl += `^FT${pos === 'bottomRight'? posTable[labelResolution]['bRQR'] : posTable[labelResolution][pos]}^BQN,2,5^FDLA,[[QRCODE]]^FS`
        }
        else if(labelConfig?.[pos]?.value === '[[BARCODE]]') {
            zpl += `^FT${posTable[labelResolution][pos]}^A@N,18,18,ARI000.FNT^BCN,50,Y,N,N,N^FD[[BARCODE]]^FS`
        } else {
            zpl += `^FT${posTable[labelResolution][pos]}^A@N,${fontSizeTable[labelConfig?.[pos]?.fontSize]},${fntTable[labelConfig?.[pos]?.type]}^FH^CI28^FD${labelConfig?.[pos]?.value}^FS`
        }
    })

    zpl+= '^CI27^PQ1,,,Y^XZ'

    return zpl
}