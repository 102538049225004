import { Background } from '@/Components/Background';
import { ConfirmModal } from '@/Components/ConfirmModal';
import { Header } from '@/Components/Header';
import ModalLoading from '@/Components/ModalLoading';
import { WhiteBackground } from '@/Components/WhiteBackground/styled';
import React, { useEffect, useState } from 'react'
import { ParticipantAddForm, SubmitButton, WarnText } from './styled';
import { Col, Form, Row } from 'react-bootstrap';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TButton } from '@/Components/TButton';
import ApiService from '@/Services/api/ApiService';
import { Attraction, AttractionRequest } from '@/Services/api/models/attractionsModels';
import { UsersResponse } from '@/Services/api/models/usersModel';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SignInResponse } from '@/Services/api/models/signInModel';
import { EventResponse } from '@/Services/api/models/eventModel';
import ControleDeAcessoService from '@/Services/controle-de-acesso/ControleDeAcessoService';
import { Group, Room, RoomRequest } from '@/Services/api/models/ControleDeAcessoModels';

export interface SalaFormValues {
    room_name: string;
    room_availability: string;
    allowed_categories: string;
    group: number;
}

export default function AddSala() {
    const navigate = useNavigate()
    const { eventID, roomId } = useParams()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [userData, setUserData] = useState<SignInResponse>()
    const [currentEvent, setCurrentEvent] = useState<EventResponse>()
    const [groups, setGroups] = useState<Group[]>()
    const [roomToEdit, setRoomToEdit] = useState<Room>()


    function createYupSchema() {
        const schema = {
            room_name: yup.string().required('Este campo é obrigatório'),
            room_availability: yup.number().typeError('Por favor, preencha a quantidade de lugares disponíveis').required('Por favor, preencha a quantidade de lugares disponíveis'),
            allowed_categories: yup.string().nullable(),
            group: yup.number().required()
        };
        
        return schema;

    }

    const validateSchema = yup.object().shape(createYupSchema());

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<SalaFormValues>({
        resolver: yupResolver(validateSchema),
    });

    const handleSubmitForm = async (data: SalaFormValues) => {
        try{
            setIsLoading(true)
            const createRoomRequest: RoomRequest = {
                room_name: data.room_name,
                room_availability: Number(data.room_availability),
                allowed_categories: (data.allowed_categories && data.allowed_categories !== '0')? data.allowed_categories : null,
                rentpass_event: Number(eventID),
                group: Number(data?.group),
                user: userData?.user?.id,
                token: userData?.token
            }
            if(roomToEdit) {
                createRoomRequest.id = Number(roomId)
                const response = await ControleDeAcessoService.editRoom(createRoomRequest)    
            }
            else {
                const response = await ControleDeAcessoService.createRoom(createRoomRequest)
            }
            toast.success('Sala criada com sucesso')
            navigate(-1)
        } catch (e: any) {
            toast.error(e?.response?.data?.message ?? 'Erro desconhecido');
        } finally {
            setIsLoading(false);
        }
    }

    const getEventGroups = async () => {
        setIsLoading(true)
        try{
            const data = await ControleDeAcessoService.getGroupsByEvent(Number(eventID))
            setGroups(data)
        }
        catch (e: any) {
            toast.error(e?.response?.data?.message ?? 'Erro desconhecido')
        }
        finally{
            setIsLoading(false)
        }
    }

    const handleGetUserInfo = async () => {
        const data = await JSON.parse(localStorage?.getItem('token') ?? '')
        setUserData(data)
    }

    const handleGetRoom = async () => {
        setIsLoading(true)
        try {
            const response = await ControleDeAcessoService.getRoomById(Number(roomId))
            setRoomToEdit(response)
        } catch(error: any) {
            toast.error('Ocorreu um erro ao comunicar com a API')
        } finally {
            setIsLoading(false)
        }
    }

    const handleGetEventByID = async (id: number) => {
        ApiService.getEventById(id)
        .then((response) => console.log(response))
        try {
            setIsLoading(true);
            const events = await ApiService.getEventById(id);
            setCurrentEvent(events);
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };
    
    useEffect(() => {
        handleGetUserInfo()
        handleGetEventByID(Number(eventID))
        getEventGroups()
    },[])

    useEffect(() => {
        if(roomId) {
            handleGetRoom()
        }
    },[roomId])

    useEffect(() => {
        if(roomToEdit) {
            setValue('room_name', roomToEdit.room_name)
            setValue('room_availability', roomToEdit.room_availability?.toString())
            setValue('group', roomToEdit.group)
            setValue('allowed_categories', roomToEdit.allowed_categories)
        }
    },[roomToEdit])

  return (
    <Background>
            <ModalLoading isActive={isLoading} />
            <Header pageTitle='Capture Control' />
            <WhiteBackground>
                <ParticipantAddForm>
                    <Form onSubmit={handleSubmit(handleSubmitForm)}>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                Nome
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control {...register('room_name')} type='text' />
                                {errors.room_name?.message && (
                                    <WarnText> {errors.room_name.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                Lugares disponíveis
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control {...register('room_availability')} type='number' />
                                {errors.room_availability?.message && (
                                    <WarnText> {errors.room_availability.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                Grupo
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Select {...register('group')}>
                                    <option value={0}> -- Selecione -- </option>
                                    {groups?.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {' '}
                                            {item.group_name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Form.Group>
                        {currentEvent?.additionalCategories?.split(',')?.length > 1 &&
                            <Form.Group as={Row} className='mb-3'>
                                <Form.Label column sm={2}>
                                    Categorias Permitidas
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Select
                                        aria-label='Default select example'
                                        {...register('allowed_categories')}
                                    >
                                        <option key={0} value={0}>--Selecione--</option>
                                        {currentEvent?.additionalCategories?.split(',').map((item) => (
                                            <option key={item} value={item}>
                                                {' '}
                                                {item}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    {errors.allowed_categories?.message && (
                                        <WarnText> {errors.allowed_categories.message} </WarnText>
                                    )}
                                </Col>
                            </Form.Group>
                        }
                        <SubmitButton>
                            <TButton type='submit'> Gravar </TButton>
                        </SubmitButton>
                    </Form>
                </ParticipantAddForm>
            </WhiteBackground>
        </Background>
  )
}
