import { Background } from '@/Components/Background';
import { Header } from '@/Components/Header';
import ModalLoading from '@/Components/ModalLoading';
import React, { useEffect, useRef, useState } from 'react';
import {
    CapitalizedRow,
    CustomLabel,
    DateColumn,
    DivergencyItemContainer,
    DivergencyTitle,
    EventTitle,
    FilterContainer,
    FilterFields,
    GetParticipantsContainer,
    Grow,
    GrowRight,
    HeaderRow,
    ImportExcel,
    NotificationCircle,
    Row,
    SmallerColumn,
    StatusButton,
    StyledDropdownToggle,
    WhiteBoxContainer,
    YellowFormButton,
} from './styled';
import { ActionButtonOptions, MassActionButtonOptions, TableHeader } from '@/Models/CrudTableModel';
import { Form, Table } from 'react-bootstrap';
import { DropdownButton } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
    CreateParticipantRequest,
    GetParticipantResponse,
} from '@/Services/api/models/participantsModel';

import * as xlsx from 'xlsx';
import { ExportToExcel, readExcel } from '@/util/SaveFromExcel';
import { FormButton } from '@/Components/FormButton';
import ApiService from '@/Services/api/ApiService';
import { useToken } from '@/Components/UseToken';
import { EventResponse } from '@/Services/api/models/eventModel';

import { toast } from 'react-toastify';
import { handlePrintTicket } from '@/util/print';
import { ConfirmModal } from '../../../Components/ConfirmModal';
import { ParticipantCategories } from '@/Services/api/models/participantCategoryModel';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import ModalImportParticipant from '@/Components/ModalImportParticipant';
import { ConfirmDeleteModal } from '@/Components/ConfirmDeleteModal';
import RefreshIcon from '@mui/icons-material/Refresh';
import ModalParticipantInfo from '@/Components/ModalParticipantInfo';
import ModalAppConfig from '@/Components/ModalAppConfig';
import ControleDeAcessoService from '@/Services/controle-de-acesso/ControleDeAcessoService';
import { HiddenInput } from '@/Pages/AutoAttendant/Homepage/styled';
import { useForm } from 'react-hook-form';
import { Keyboard, QrCode } from '@mui/icons-material';
import ModalAttendant from '@/Components/ModalAttendant';

interface ExcelTemplate {
    CdExternoAux: number;
    CdEvento: number;
    Doc: string;
    Nome: string;
    SobreNome: string;
    Empresa: string;
    Cargo: string;
    TelCelular: string;
    TelComercial: string;
    Email: string;
    Estado: string;
    Cidade: string;
    Sincronizacao: number;
    DhInclusao: Date;
    DhAlteracao: Date;
    Inativo: boolean;
    CheckIn: string;
    Categoria: String;
    Obs: string;
    CategoriaAdicional: string;
    CheckInDuration: number;
    QRCode: string;
}

export function GetParticipants() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [participants, setParticipants] = useState<GetParticipantResponse[]>();
    const [currentEvent, setCurrentEvent] = useState<EventResponse>();
    const [divergencyMsgs, setDivergencyMsgs] = useState<string[]>([]);
    const [attendantModal, setAttendantModal] = useState<boolean>(false)

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [lastParticipant, setLastParticipant] = useState<GetParticipantResponse>();

    const [checkedCheckBox, setCheckedCheckBox] = useState<number[]>([]);
    const [allCheckBoxChecked, setAllCheckBoxChecked] = useState<boolean>(false);

    const [participantFilter, setParticipantFilter] = useState<string>('');
    const [categoryFilter, setCategoryFilter] = useState<number>();
    const [originFilter, setOriginFilter] = useState<string>()
    const [participantsFiltered, setParticipantsFiltered] = useState<GetParticipantResponse[]>()
    const [participantCategories, setParticipantCategories] = useState<Array<ParticipantCategories>>([])
    const [additionalCategoryFilter, setAdditionalCategoryFilter] = useState<string>()

    const [importModal, setImportModal] = useState<boolean>(false)
    const [appConfigModal, setAppConfigModal] = useState<boolean>(false)
    const [participantInfoModal, setParticipantInfoModal] = useState<GetParticipantResponse>(null)
    const [participantToDelete, setParticipantToDelete] = useState<GetParticipantResponse>()
    const [mobile, setMobile] = useState<boolean>(false)
    const [update, setUpdate] = useState<number>(0)
    
    const { token } = useToken();
    const navigate = useNavigate();
    const { eventID } = useParams();

    useEffect(() => {
        if(window.innerWidth < 1200){
            setMobile(true)
        }
    },[])


    const {setValue, watch, handleSubmit, register} = useForm<{document: string}>()

    const handleGetParticipants = async () => {
        try {
            setIsLoading(true);
            const participants = await ApiService.getAllParticipantsByEvent(Number(eventID));
            if (token?.user.userGroup !== 'RECEPTION') {
                await getDivergencyParticipants();
            }
            setParticipants(participants);
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const getDivergencyParticipants = async () => {
        try {
            setIsLoading(true);
            const divergency = await ApiService.getDivergencyParticipantsByEvent(Number(eventID));
            console.log(divergency)
            setDivergencyMsgs(divergency);
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleGetEventInfos = async () => {
        try {
            setIsLoading(true);
            const event = await ApiService.getEventById(Number(eventID));
            setCurrentEvent(event);
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const formatDate = (date: string) => {
        if (!Date.parse(date)) {
            return null;
        }

        const splitedDate = date.split(' ');
        const newDate = new Date(`${splitedDate[0]} ${splitedDate[1]}Z`);
        return newDate.toISOString();
    };

    const handleImportFromExcel = async (file: any) => {
        try {
            const participants = await readExcel<ExcelTemplate>(file);
            const insertedParticipants: Promise<any>[] = [];
            const request: Array<CreateParticipantRequest> = []
            setIsLoading(true);
            for (let participant of participants) {
                 request.push({
                    name: participant.Nome?.trim() ?? null,
                    lastName: participant.SobreNome?.trim() ?? null,
                    state: participant.Estado?.trim() ?? null,
                    email: participant.Email?.trim() ?? null,
                    city: participant.Cidade ?? null,
                    cellPhoneNumber: participant.TelCelular ?? null,
                    commercialPhoneNumber: participant.TelComercial ?? null,
                    documentNumber: participant.Doc ?? null,
                    companyPosition: participant.Cargo ?? null,
                    companyName: participant.Empresa ?? null,
                    eventId: Number(eventID),
                    id: undefined,
                    checkinDate: participant.CheckIn ? formatDate(participant.CheckIn) : null,
                    externalCodeAux: participant.CdExternoAux ?? 0,
                    registrationOrigin: token?.user.userGroup ?? '',
                    isImportedFromExcel: true,
                    hasDivergence: false,
                    active: true,
                    categoryId: participantCategories.find((category) => category.categoryName === participant.Categoria)?.id ?? 1,
                    linkOrigin: '',
                    additionalInformation: participant.Obs ?? '',
                    additionalCategory: participant?.CategoriaAdicional ?? null,
                    checkInDuration: 0,
                    checkInQrCode: participant?.QRCode ?? null,
                })
            }
            const apiCall: {participantBulkResponseList?: Array<{participant: GetParticipantResponse, messageErrors: string[], success: boolean}>} = await ApiService.bulkInsertParticipants(request)

            const unsuccessful = apiCall.participantBulkResponseList.filter((participant) => !participant.success)
            if(unsuccessful.length === 0) {
                toast.success('Finalizada a importação de participantes');
            }
            else {
                toast.error('Erro ao importar alguns participantes');
                toast.error(unsuccessful.map((participant) => participant.messageErrors));
            }
        } catch (e: any) {
            toast.error('Erro ao importar alguns participantes');
            toast.error(e.toString());
        } finally {
            handleGetParticipants();
            setIsLoading(false);
            setImportModal(false)
        }
    };

    const handleDownloadTemplate = () => {
        const sheetTemplate: ExcelTemplate[] = [];
        sheetTemplate.push({
            CdEvento: 4124,
            CdExternoAux: 51235,
            Doc: '654321',
            Nome: 'Ewerton',
            SobreNome: 'Silva',
            Empresa: 'EwerTI',
            Cargo: 'Gerente',
            TelComercial: '11-98856-5898',
            TelCelular: '11-98856-5898',
            Email: 'levy@gmail.com',
            Estado: 'SP',
            Cidade: 'São Paulo',
            Inativo: false,
            DhAlteracao: new Date(),
            DhInclusao: new Date(),
            CheckIn: '10-10-2022T12:00:00',
            Sincronizacao: 0,
            Categoria: 'Staff',
            Obs: '',
            CategoriaAdicional: '',
            CheckInDuration: 0,
            QRCode: ''
        });

        sheetTemplate.push({
            CdEvento: 4124,
            CdExternoAux: 51235,
            Doc: '654321',
            Nome: 'Levy',
            SobreNome: 'Cauã',
            Empresa: 'AVSC',
            Cargo: 'Gerente',
            TelComercial: '11-98856-5898',
            TelCelular: '11-98856-5898',
            Email: 'levy@gmail.com',
            Estado: 'SP',
            Cidade: 'São Paulo',
            Inativo: false,
            DhAlteracao: new Date(),
            DhInclusao: new Date(),
            CheckIn: '10-10-2022T12:00:00',
            Sincronizacao: 0,
            Categoria: 'Participante',
            Obs: '',
            CategoriaAdicional: '',
            CheckInDuration: 0,
            QRCode: ''
        });

        const wb = xlsx.utils.book_new(),
            ws = xlsx.utils.json_to_sheet(sheetTemplate);

        xlsx.utils.book_append_sheet(wb, ws, 'Dados');

        xlsx.writeFile(wb, 'template_rentpass.xlsx');
    };

    const handleExportToExcel = async () => {
        if (!participants) return;
        const modules = []
        if(currentEvent?.kitQuantityLimit > 0) {
            modules.push('cek')
        }
        ExportToExcel(currentEvent?.description ?? '', participants, participantCategories, modules);
    }

    const participantOrigins = participants?.reduce((acc, participant) => {
        if (participant.linkOrigin && participant.linkOrigin.trim() !== '' && !acc.includes(participant.linkOrigin)) {
            acc.push(participant.linkOrigin);
        }
        return acc;
    }, []);

    const participantAdditionalCategories = participants?.reduce((acc, participant) => {
        if (participant.additionalCategory && participant.additionalCategory.trim() !== '' && !acc.includes(participant.additionalCategory)) {
            acc.push(participant.additionalCategory);
        }
        return acc;
    }, []);

    const FormatedColumns: TableHeader<CreateParticipantRequest>[] = [
        {
            title: 'Nome',
            key: 'name',
            filter: true,
        },
        {
            title: 'E-mail',
            key: 'email',
            filter: true,
        },
        {
            title: 'Documento',
            key: 'documentNumber',
            filter: true,
        },
        {
            title: 'Empresa',
            key: 'companyName',
            filter: true,
        },
        {
            title: 'Categoria',
            key: 'categoryId',
            filter: true,
        },
        {
            title: 'Check-in',
            key: 'cellPhoneNumber',
        },
        {
            title: 'Inativo',
            key: 'active',
        },
        {
            title: 'Origem Cadastro',
            key: 'id',
        },
        {
            title: 'Importado Excel',
            key: 'active',
        },
    ];

    if (currentEvent?.additionalCategories) {
        FormatedColumns.splice(6,0,{
            title: 'Categoria Adicional',
            key: 'id',
        })
    }


    const handleEditParticipant = (id: number) => {
        navigate('/participantes/editar/' + id);
    };

    const handleReprintCredential = async (participant: GetParticipantResponse) => {
        try {
            setIsLoading(true);
            if (!participant.checkinDate) {
                throw new Error(
                    'Check-in não foi realizado ainda, não é possível realizar a impressão',
                );
            }

            if (!currentEvent) {
                throw new Error('Evento não está configurado');
            }

            await handlePrintTicket(currentEvent, participant);
        } catch (e: any) {
            toast.error(e.message);
        } finally {
            setIsLoading(false);
        }
    };


    const handleMassPrint = async () => {
        setIsLoading(true)
        const selectedParticipants = checkedCheckBox?.map(p => 
            participants?.find(participant => participant.id === p)
        ).filter(Boolean);
    
        const sortedParticipants = selectedParticipants.sort((a, b) => {
            const textA = a.name.toUpperCase();
            const textB = b.name.toUpperCase();
            return textA.localeCompare(textB);
        });
    
        for (const participant of sortedParticipants) {
            try {
                await handlePrintTicket(currentEvent, participant);
            } catch (error) {
                console.error(`Error printing ticket for participant ${participant.name}:`, error);
            }
        }
        setIsLoading(false)
    }

    const handleCheckIn = async (participant: GetParticipantResponse) => {
        try {
            setIsLoading(true);

            if (!currentEvent) {
                throw new Error('Evento não configurado');
            }

            const d = new Date();
            d.setHours(d.getHours() - 3);
            await ApiService.checkInParticipant(participant.id, d.toISOString(), 0);
            setLastParticipant(participant);

            toast.success('Checkin realizado com sucesso!');
            handleGetParticipants();
            setShowConfirmModal(true);
        } catch (e: any) {
            if(e.response?.data?.message.includes('CheckIn já realizado para o participante')) {
                toast.error('Check-in já realizado');    
            }
            else {
                toast.error(e.message);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleRemoverCheckIn = async (participant: GetParticipantResponse) => {
        try {
            setIsLoading(true);

            await ApiService.upInsertParticipant({
                name: participant.name,
                lastName: participant.lastName,
                state: participant.state,
                email: participant.email,
                city: participant.city,
                cellPhoneNumber: participant.cellPhoneNumber,
                commercialPhoneNumber: participant.commercialPhoneNumber,
                documentNumber: participant.documentNumber,
                companyPosition: participant.companyPosition,
                companyName: participant.companyName,
                eventId: Number(eventID),
                id: participant.id,
                checkinDate: null,
                externalCodeAux: participant.externalCodeAux,
                registrationOrigin: participant.registrationOrigin,
                isImportedFromExcel: participant.isImportedFromExcel,
                hasDivergence: participant.hasDivergence,
                active: participant.active,
                categoryId: participant.categoryId,
                linkOrigin: participant.linkOrigin,
                additionalInformation: participant.additionalInformation,
                additionalCategory: participant.additionalCategory,
                checkInDuration: 0,
                checkInQrCode: participant.checkInQrCode
            });

            setLastParticipant(participant);

            toast.success('Checkin Removido com sucesso!');
            handleGetParticipants();
        } catch (e: any) {
            toast.error(e.message);
        } finally {
            setIsLoading(false);
        }
    };
    
    useEffect(() => {
        setParticipantsFiltered(participants?.filter((participant) => {
            if (!participantFilter && categoryFilter) {
                return participant.categoryId === categoryFilter;
            } else if (originFilter) {
                return participant.linkOrigin && participant.linkOrigin === originFilter;
            } else if (additionalCategoryFilter) {
                return participant.additionalCategory && participant.additionalCategory === additionalCategoryFilter;
            } else if (participantFilter) {
                return (
                    `${participant.name}${participant.lastName}`.toLowerCase()
                    .includes(participantFilter.toLowerCase().replaceAll(' ','')) ||
                    `${participant.documentNumber}`.toLowerCase()
                    .includes(participantFilter.toLowerCase())
                );
            } else {
                return true; // No filters applied
            }
        }));
    }, [isLoading, participantFilter, categoryFilter, participants, originFilter, additionalCategoryFilter]);
    

    const searchByQRCode = () => {
        if(watch('document') && watch('document') !== '') {
            const value = watch('document')?.replaceAll(';', '/')?.replaceAll(':','?')?.replaceAll('Ç',':')
            setParticipantsFiltered(participants?.filter((p) => p?.checkInQrCode === value))
            setValue('document', '')
        }
    }


    const handleMultipleCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = e.target;

        if (checked) {
            setCheckedCheckBox([...checkedCheckBox, +value]);
        } else {
            setCheckedCheckBox(checkedCheckBox.filter((item) => item !== +value));
        }
    };

    const handleSelectAllParticipants = () => {
            if(allCheckBoxChecked) {
                setAllCheckBoxChecked(false)
                setCheckedCheckBox([])
            }
            else {
                setAllCheckBoxChecked(true)
                setCheckedCheckBox(participantsFiltered.map((participant) => {return participant.id}));
            }
    }

    const handleDeleteParticipant = async (id: number) => {
        try {
            setIsLoading(true);
            await ApiService.deleteParticipant(id)
            await handleGetParticipants()
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleInativarSelecionados = async () => {
        try {
            setIsLoading(true);
            if (!checkedCheckBox || checkedCheckBox.length <= 0) {
                throw new Error('Nenhum participante selecionado');
            }
            let promises = [];
            for (let i = 0; i < checkedCheckBox.length; i++) {
                const currentParticipant = participants?.find(
                    (participant) => participant.id === checkedCheckBox[i],
                );
                if (!currentParticipant) return;
                promises.push(
                    ApiService.upInsertParticipant({
                        ...currentParticipant,
                        active: false,
                    }),
                );
            }
            await Promise.all(promises);

            toast.success('Participantes inativados com sucesso');
            handleGetParticipants();
        } catch (e: any) {
            toast.error(e.message);
        } finally {
            setIsLoading(false);
        }
    };

    const actionOptions: ActionButtonOptions<GetParticipantResponse>[] = [
        {
            title: 'Editar',
            onClick: (participant) => handleEditParticipant(participant.id ?? 0),
        },
        {
            title: 'Realizar Check-in',
            onClick: (participant) => handleCheckIn(participant),
        },
        {
            title: 'Reimprimir Credencial',
            onClick: (participant) => handleReprintCredential(participant),
        },
    ];

    if (token?.user.userGroup === 'ADMIN' || token?.user.userGroup === 'PARTNER_ADMIN') {
        actionOptions.push(
            {
                title: 'Remover Check-in',
                onClick: (participant) => handleRemoverCheckIn(participant),
            },
            {
                title: 'Excluir',
                onClick: (participant) => setParticipantToDelete(participant),
            },
        );
    }

    const massActionOptions: MassActionButtonOptions<GetParticipantResponse>[] = [
        {
            title: 'Unificar Selecionados',
            onClick: () => handleUnifyParticipants(),
        },
        {
            title: 'Imprimir credenciais em massa',
            onClick: () => handleMassPrint(),
        },
        {
            title: 'Inativar Selecionados',
            onClick: () => handleInativarSelecionados(),
        },
    ];

    if(token?.user.userGroup === 'ADMIN' || token?.user.userGroup === 'PARTNER_ADMIN') {
        massActionOptions.push({
            title: 'Excluir Selecionados',
            onClick: () => {checkedCheckBox.map((participant) => handleDeleteParticipant(participant)); setCheckedCheckBox([])},
        })
    }

    function removeEmpty<T extends {}>(obj: T) {
        return Object.fromEntries(
            Object.entries(obj).filter(([_, v]) => v != null && v !== undefined),
        );
    }

    const handleUnifyParticipants = async () => {
        try {
            setIsLoading(true);
            if (!checkedCheckBox || checkedCheckBox.length <= 0) {
                throw new Error('Nenhum participante selecionado');
            }

            const participantIds = [...checkedCheckBox];
            const firstParticipantId = participantIds.shift();

            const firstParticipant = participants?.find(
                (participant) => participant.id === firstParticipantId,
            );
            if (!firstParticipant) return;

            let mergedParticipants = { ...firstParticipant };

            let participantsToExclude = [];

            for (let i = 1; i < checkedCheckBox.length; i++) {
                const currentParticipant = participants?.find(
                    (participant) => participant.id === checkedCheckBox[i],
                );
                if (!currentParticipant) continue;
                mergedParticipants = {
                    ...mergedParticipants,
                    ...removeEmpty(currentParticipant),
                };
                participantsToExclude.push(currentParticipant);
            }

            const mergedParticipant: GetParticipantResponse = {
                ...mergedParticipants,
                ...removeEmpty(firstParticipant),
            };

            await ApiService.upInsertParticipant(mergedParticipant);

            participantsToExclude.map(async (participant) => {
                await ApiService.deleteParticipant(participant.id);
            });

            toast.success('Participantes unificados com sucesso');
            handleGetParticipants();
        } catch (e: any) {
            toast.error(e.message);
        } finally {
            setIsLoading(false);
        }
    };

    const getParticipantCategories = async () => {
        const categories = await ApiService.getParticipantCategories()
        setParticipantCategories(categories)
    }

    const handleSortParticipants = (sortBy: string) => {
        if(sortBy === 'Nome') {
            const sortedParticipants = [...participantsFiltered].sort((a, b) => {
                const nameA = a.name.toLowerCase(); 
                const nameB = b.name.toLowerCase();
              
                if (nameA < nameB) {
                  return -1; 
                } else if (nameA > nameB) {
                  return 1; 
                }
                return 0; 
              });
            setParticipantsFiltered(sortedParticipants)
        }
        if(sortBy === 'Check-in') {
            const sortedParticipants = [...participantsFiltered].sort((a, b) => {
                const dateA = a.checkinDate || '';
                const dateB = b.checkinDate || '';
                
                if (!dateA && !dateB) {
                  return 0;
                }

                if (!dateA) {
                  return 1;
                }
                if (!dateB) {
                  return -1;
                }
            
                const timestampA = Date.parse(dateA);
                const timestampB = Date.parse(dateB);
            
                if (timestampA < timestampB) {
                  return -1; 
                } else if (timestampA > timestampB) {
                  return 1; 
                }
                return 0; // dates are equal
              });
            
              setParticipantsFiltered(sortedParticipants);
        }
    }

    const setFocus = () => {
        const field = document?.getElementById('qrField')
        if(field) {
            field.focus()
        }
    }

    useEffect(() => {
        handleGetParticipants();
        handleGetEventInfos();
        getParticipantCategories();
    }, [update]);


    return (
        <Background>
            <ModalParticipantInfo
                show={participantInfoModal? true : false}
                onButtonClick={() => setParticipantInfoModal(null)}
                participant={participantInfoModal}
            />
            <ConfirmDeleteModal 
                show={participantToDelete? true : false}
                onClose={() => setParticipantToDelete(undefined)}
                onConfirm={() => {handleDeleteParticipant(participantToDelete.id); return setParticipantToDelete(undefined)}}
            />
            <ModalImportParticipant 
                isActive={importModal} 
                hideModal={() => setImportModal(false)}
                onButtonClick={() => handleDownloadTemplate()}
                onInputClick={(file) => handleImportFromExcel(file)}
            />
            <ModalAppConfig
                event = {currentEvent}
                isActive={appConfigModal} 
                hideModal={() => setAppConfigModal(false)}
                onButtonClick={() => {}}
                onInputClick={(file) => {}}
            />
            <ModalAttendant 
                event={currentEvent} 
                hideModal={() => setAttendantModal(false)} 
                isActive={attendantModal}
                participantList={participants}            
            />
            <ModalLoading isActive={isLoading} />
            <ConfirmModal
                show={showConfirmModal}
                onClose={() => setShowConfirmModal(false)}
                onConfirm={() => {
                    if (!currentEvent || !lastParticipant) {
                        return;
                    }

                    handlePrintTicket(currentEvent, lastParticipant);
                    setShowConfirmModal(false);
                }}
            />
            <GetParticipantsContainer>
                <Header pageTitle='Consulta de Participantes' />
                <HeaderRow>
                    <FormButton onClick={() => navigate(`/evento/${eventID}`)}>
                        Voltar para a Dashboard
                    </FormButton>
                </HeaderRow>
                <Row>
                    <EventTitle>
                        {' '}
                        <span> Evento: </span> {currentEvent?.description} ({currentEvent?.id}){' '}
                    </EventTitle>
                    <div onClick={() => setUpdate(update => update+1)}>
                        <RefreshIcon />
                    </div>
                </Row>
                {divergencyMsgs.length > 0 ? (
                    <WhiteBoxContainer>
                        <>
                            <DivergencyTitle> Incompatibilidades de cadastro </DivergencyTitle>
                            {divergencyMsgs.map((msg, index) => {
                                return (
                                    <DivergencyItemContainer key={index}>
                                        <NotificationCircle />
                                        <div className='message'> {msg} </div>
                                    </DivergencyItemContainer>
                                );
                            })}
                        </>
                    </WhiteBoxContainer>
                ) : null}
                <WhiteBoxContainer>
                    <div className='header'>
                        {mobile
                            ? <Grow>
                                <FormButton
                                    onClick={() => navigate('/participantes/adicionar/' + eventID)}
                                >
                                    {' '}
                                    Novo{' '}
                                </FormButton>
                            </Grow>
                            : <Grow>
                                <FormButton
                                    onClick={() => navigate('/participantes/adicionar/' + eventID)}
                                >
                                    {' '}
                                    Novo{' '}
                                </FormButton>
                                <FormButton
                                    onClick={() => setImportModal(true)}
                                >
                                    Importar Excel
                                </FormButton>
                                <FormButton
                                    onClick={() => setAttendantModal(true)}
                                >
                                    Leitor QR CODE
                                </FormButton>
                            </Grow>
                        }
                        <GrowRight>
                            <FormButton
                                onClick={() => {
                                    setAppConfigModal(true)
                                }}
                            >
                                {mobile? 'App' : 'Abrir no App'}
                            </FormButton>
                            <FormButton
                                onClick={() => handleExportToExcel()}
                            >
                                    Exportar
                            </FormButton>
                        </GrowRight>
                    </div>
                    {checkedCheckBox.length > 1
                            ? <div className='header'>
                                <Grow>
                                    {massActionOptions.map((button) => 
                                        <FormButton 
                                            onClick={button.onClick}
                                            className={('Inativar Selecionados'+'Excluir Selecionados').includes(button.title) ? 'bg-danger' : ''}
                                        >
                                            {button.title}
                                        </FormButton>
                                    )}
                                </Grow>
                            </div>
                            :
                            null
                        }
                    <div className='body'>
                        <FilterContainer>
                            <Form.Label column>{`${participantsFiltered?.length ?? 0} participantes`}</Form.Label>
                            <FilterFields>
                                <Form.Control
                                    type='text'
                                    placeholder='Filtrar participantes'
                                    onChange={(e) => {
                                        setParticipantFilter(e.target.value);
                                    }}
                                />
                            </FilterFields>
                        </FilterContainer>
                    </div>
                    <div className='tabela'>
                        {participantsFiltered && (
                            <Table hover id='participantsTable'>
                                <thead>
                                    <tr>
                                        <th>
                                            <Form.Check
                                                type={'checkbox'}
                                                onChange={handleSelectAllParticipants}
                                            />
                                        </th>
                                        <th>
                                        </th>
                                        {FormatedColumns.map((column, index) => 
                                            column.title === 'Categoria'
                                            ? <th>
                                                <Dropdown>
                                                    <StyledDropdownToggle
                                                        variant='secondary'
                                                    >
                                                        Categoria 1
                                                    </StyledDropdownToggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                            key={'todos'}
                                                            onClick={() => {
                                                                setCategoryFilter(null)
                                                            }}
                                                        >
                                                            Todos
                                                        </Dropdown.Item>
                                                        {participantCategories.map((category) => 
                                                            <Dropdown.Item
                                                                key={category.categoryName}
                                                                onClick={() => {
                                                                    setCategoryFilter(category.id)
                                                                }}
                                                            >
                                                                {' '}
                                                                {category.categoryName}{' '}
                                                            </Dropdown.Item>
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </th>
                                            : 
                                                column.title === 'Origem Cadastro'
                                            ? <th>
                                                <Dropdown>
                                                    <StyledDropdownToggle
                                                        variant='secondary'
                                                    >
                                                        Origem Cadastro
                                                    </StyledDropdownToggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                            key={'todos'}
                                                            onClick={() => {
                                                                setOriginFilter(undefined)
                                                            }}
                                                        >
                                                            Todos
                                                        </Dropdown.Item>
                                                        {participantOrigins?.map((origin) => 
                                                            <Dropdown.Item
                                                                key={origin}
                                                                onClick={() => {
                                                                    setOriginFilter(origin)
                                                                }}
                                                            >
                                                                {' '}
                                                                {origin}{' '}
                                                            </Dropdown.Item>
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </th> 
                                            :
                                                column.title === 'Categoria Adicional'
                                            ?
                                                <th>
                                                    <Dropdown>
                                                        <StyledDropdownToggle
                                                            variant='secondary'
                                                        >
                                                            Categoria 2
                                                        </StyledDropdownToggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item
                                                                key={'todos'}
                                                                onClick={() => {
                                                                    setAdditionalCategoryFilter(undefined)
                                                                }}
                                                            >
                                                                Todos
                                                            </Dropdown.Item>
                                                            {participantAdditionalCategories?.map((origin) => 
                                                                <Dropdown.Item
                                                                    key={origin}
                                                                    onClick={() => {
                                                                        setAdditionalCategoryFilter(origin)
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    {origin}{' '}
                                                                </Dropdown.Item>
                                                            )}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </th>    
                                            :
                                               <th key={index} onClick={() => handleSortParticipants(column.title)}> {column.title} </th>
                                        )}

                                        {currentEvent?.kitQuantityLimit > 0
                                            ? <th key={'cek'}> CEK </th>
                                            : <></>
                                        }
                                        {token?.user.userGroup === 'RECEPTION' &&
                                            <th key={'checkin'}></th>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {participantsFiltered.map((participantMapped, index) => {
                                        return (
                                            <tr
                                                key={participantMapped.id}
                                                className={
                                                    participantMapped.hasDivergence &&
                                                    divergencyMsgs.length > 0
                                                        ? 'highlighted'
                                                        : ''
                                                }
                                                onDoubleClick={() => setParticipantInfoModal(participantMapped)}
                                            >
                                                <td>
                                                    {' '}
                                                    <Form.Check
                                                        type={'checkbox'}
                                                        value={participantMapped.id}
                                                        onChange={handleMultipleCheckBox}
                                                        checked={checkedCheckBox.includes(participantMapped.id)}
                                                    />{' '}
                                                </td>
                                                <SmallerColumn>
                                                    {' '}
                                                    <DropdownButton
                                                        variant='warning'
                                                        id={`dropdown-variants-Warning`}
                                                        title={'Ação'}
                                                        color={'white'}
                                                        className='teste'
                                                        size='sm'
                                                    >
                                                        {actionOptions.map((action) => {
                                                            return (
                                                                <Dropdown.Item
                                                                    key={action.title}
                                                                    onClick={() =>
                                                                        action.onClick(
                                                                            participantMapped,
                                                                        )
                                                                    }
                                                                >
                                                                    {' '}
                                                                    {action.title}{' '}
                                                                </Dropdown.Item>
                                                            );
                                                        })}
                                                    </DropdownButton>{' '}
                                                </SmallerColumn>
                                                <td> {participantMapped.name} </td>
                                                <td> {participantMapped.email} </td>
                                                <td> {participantMapped.documentNumber} </td>
                                                <td> {participantMapped.companyName} </td>
                                                <td> {participantCategories.find(category => category.id === participantMapped.categoryId)?.categoryName} </td>
                                                <DateColumn>
                                                    {participantMapped.checkinDate &&
                                                        `${
                                                            participantMapped.checkinDate.split(
                                                                'T',
                                                            )[0]
                                                        } ${
                                                            participantMapped.checkinDate.split(
                                                                'T',
                                                            )[1]
                                                        }`}
                                                </DateColumn>
                                                {currentEvent?.additionalCategories && <td>{participantMapped.additionalCategory}</td>}
                                                <SmallerColumn>
                                                    <StatusButton
                                                        className={
                                                            participantMapped.active
                                                                ? 'active'
                                                                : 'inative'
                                                        }
                                                    >
                                                        {participantMapped.active
                                                            ? 'Ativo'
                                                            : 'Inativo'}
                                                    </StatusButton>
                                                </SmallerColumn>
                                                <td>
                                                    {' '}
                                                    <CapitalizedRow>
                                                        {participantMapped.linkOrigin && participantMapped.linkOrigin !== ''? participantMapped.linkOrigin : participantMapped.registrationOrigin.toLocaleLowerCase()}
                                                    </CapitalizedRow>{' '}
                                                </td>
                                                <SmallerColumn>
                                                    <StatusButton
                                                        className={
                                                            participantMapped.isImportedFromExcel
                                                                ? 'active'
                                                                : 'inative'
                                                        }
                                                    >
                                                        {participantMapped.isImportedFromExcel
                                                            ? 'SIM'
                                                            : 'NÃO'}
                                                    </StatusButton>
                                                </SmallerColumn>


                                                {currentEvent?.kitQuantityLimit > 0
                                                    ? <DateColumn>
                                                        {participantMapped.cekWithdrawDate &&
                                                            `${
                                                                participantMapped.cekWithdrawDate.split(
                                                                    'T',
                                                                )[0]
                                                            } ${
                                                                participantMapped.cekWithdrawDate.split(
                                                                    'T',
                                                                )[1]
                                                            }`}
                                                    </DateColumn>
                                                    : <></>
                                                }
                                                {token?.user.userGroup === 'RECEPTION' &&
                                                    <SmallerColumn>
                                                        <StatusButton 
                                                            className='action'
                                                            onClick={() => handleCheckIn(participantMapped)}
                                                        >
                                                            Check-in
                                                        </StatusButton>
                                                    </SmallerColumn>
                                                }
                                            </tr>
                                        );
                                    })}

                                </tbody>
                            </Table>
                        )}
                    </div>
                </WhiteBoxContainer>
            </GetParticipantsContainer>
        </Background>
    );
}
