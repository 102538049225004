import { Background } from '@/Components/Background';
import { Header } from '@/Components/Header';
import { TButton } from '@/Components/TButton';
import { ButtonGroup, Col, Form, Row, ToggleButton } from 'react-bootstrap';
import { DashedHR, ParticipantAddForm, SubmitButton, WarnText } from './styled';

import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import ApiService from '@/Services/api/ApiService';

import { SubmitHandler } from 'react-hook-form';
import ModalLoading from '@/Components/ModalLoading';

import 'react-datepicker/dist/react-datepicker.css';
import {
    CreateParticipantRequest,
    GetParticipantResponse,
} from '@/Services/api/models/participantsModel';

import EstadosBrasileiros from '@/util/estadosBrasileiros.json';
import { useToken } from '@/Components/UseToken';
import { toast } from 'react-toastify';
import { WhiteBackground } from '@/Components/WhiteBackground/styled';
import { ConfirmModal } from '@/Components/ConfirmModal';
import { EventResponse } from '@/Services/api/models/eventModel';
import { handlePrintTicket } from '@/util/print';
import { ParticipantCategories } from '@/Services/api/models/participantCategoryModel';
import ControleDeAcessoService from '@/Services/controle-de-acesso/ControleDeAcessoService';
import { Room } from '@/Services/api/models/ControleDeAcessoModels';

export interface ParticipantFormValues {
    nome: String;
    sobrenome: String;
    estado: String;
    cidade: String;
    empresa: String;
    cargo: String;
    documento: String;
    telCelular: String;
    telComercial: String;
    email: String;
    ativo: Boolean;
    categoria: number;
    obs: string;
    additionalCategory: string;
}


export function AddParticipant() {
    const navigate = useNavigate();

    const [participantToEdit, setParticipantToEdit] = useState<GetParticipantResponse>();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const [currentEvent, setCurrentEvent] = useState<EventResponse>();
    const [currentParticipant, setCurrentParticipant] = useState<GetParticipantResponse>();
    const [particitantCategories, setParticipantCategories] = useState<Array<ParticipantCategories>>([])

    const { eventID, userID } = useParams();

    const { token } = useToken();


    function createYupSchema() {
        const requiredFields = currentEvent?.requiredFields?.split(',');


        const schema = {
            nome: yup.string().nullable(),
            sobrenome: yup.string().nullable(),
            documento: yup.string().nullable(),
            estado: yup.string().nullable(),
            cidade: yup.string().nullable(),
            empresa: yup.string().nullable(),
            cargo: yup.string().nullable(),
            telCelular: yup.string().nullable(),
            telComercial: yup.string().nullable(),
            email: yup.string().nullable(),
            ativo: yup.boolean().nullable(),
            categoria: yup.number().required('O campo categoria é obrigatório').min(1,'Selecione uma categoria'),
            obs: yup.string().nullable(),
            additionalCategory: yup.string().nullable()
        };
        
        if (!requiredFields) return schema;

        requiredFields.forEach((field) => {
            // @ts-ignore
            if (!schema[field]) {
                return;
            }

            // @ts-ignore
            schema[field] = schema[field].required(`${field.charAt(0).toUpperCase()}${field.slice(1)} é obrigatório`)
        })

        return schema
    }

    const validateSchema = yup.object().shape(createYupSchema());

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
    } = useForm<ParticipantFormValues>({
        resolver: yupResolver(validateSchema),
    });

    useEffect(() => {
        ApiService.getParticipantCategories()
        .then((data) => {
            setParticipantCategories(data)
            setValue('categoria', data.find((c) => c.categoryName === 'Participante')?.id)
        })
        if (userID) {
            handleGetParticipantByID(+userID);
        } else {
            handleGetEventByID(Number(eventID))
        }
    }, []);

    useEffect(() => {
        if (userID) {
            if (participantToEdit){
                handleGetEventByID(participantToEdit.eventId)
            }
        }
    },[participantToEdit])

    useEffect(() => {
        if (participantToEdit) {
            setValue('nome', participantToEdit.name);
            setValue('sobrenome', participantToEdit.lastName);
            setValue('documento', participantToEdit.documentNumber);
            setValue('empresa', participantToEdit.companyName);
            setValue('cargo', participantToEdit.companyPosition);
            setValue('telCelular', participantToEdit.cellPhoneNumber);
            setValue('telComercial', participantToEdit.commercialPhoneNumber);
            setValue('email', participantToEdit.email);
            setValue('cidade', participantToEdit.city);
            setValue('estado', participantToEdit.state);
            setValue('ativo', !participantToEdit.active);
            setValue('categoria', participantToEdit.categoryId);
            setValue('obs',participantToEdit?.additionalInformation);
            setValue('additionalCategory', participantToEdit?.additionalCategory)
        }
    }, [participantToEdit]);

    const handleGetParticipantByID = async (id: number) => {
        try {
            setIsLoading(true);
            const response = await ApiService.getParticipantByID(id);
            setParticipantToEdit(response);
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleGetEventByID = async (id: number) => {
        ApiService.getEventById(id)
        .then((response) => console.log(response))
        try {
            setIsLoading(true);
            const events = await ApiService.getEventById(id);
            setCurrentEvent(events);
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmitForm: SubmitHandler<ParticipantFormValues> = async (data) => {
        try {
            setIsLoading(true);
            // TODO:: Dúvidas sobre "details" e "final Date"
            const createParticipantRequest: CreateParticipantRequest = {
                name: data.nome,
                lastName: data.sobrenome,
                state: data.estado,
                email: data.email?.trim(),
                city: data.cidade,
                cellPhoneNumber: data.telCelular,
                commercialPhoneNumber: data.telComercial,
                companyName: data.empresa,
                companyPosition: data.cargo,
                documentNumber: data.documento || null,
                active: !data.ativo,
                categoryId: data.categoria,
                id: undefined,
                eventId: Number(eventID),
                registrationOrigin: token?.user?.userGroup === 'PARTNER_ADMIN'? 'ADMIN' : token?.user?.userGroup ?? '',
                checkinDate: null,
                externalCodeAux: 0,
                hasDivergence: false,
                isImportedFromExcel: false,
                linkOrigin: '',
                additionalInformation: data.obs,
                additionalCategory: data?.additionalCategory ?? null,
                checkInDuration: 0,
                checkInQrCode: null
            };
            if (!!participantToEdit?.id) {
                createParticipantRequest.id = participantToEdit.id;
                createParticipantRequest.eventId = participantToEdit.eventId;
                createParticipantRequest.registrationOrigin = participantToEdit.registrationOrigin;
                createParticipantRequest.checkinDate = participantToEdit.checkinDate;
                createParticipantRequest.externalCodeAux = participantToEdit.externalCodeAux;
                createParticipantRequest.isImportedFromExcel = participantToEdit.isImportedFromExcel;
                createParticipantRequest.linkOrigin = participantToEdit.linkOrigin;
                createParticipantRequest.checkInDuration = participantToEdit.checkInDuration
                createParticipantRequest.checkInQrCode = participantToEdit.checkInQrCode
            }
            const response = await ApiService.upInsertParticipant(createParticipantRequest);

            setCurrentParticipant(response);


            if (currentEvent?.accessControl && currentEvent?.additionalCategories) {
                try {
                    const rooms:Room[] = await ControleDeAcessoService.getRoomsByEvent(currentEvent?.id);
                    if (rooms) {
                        const registrationPromises = rooms
                        .map(async (r) => {
                            if (!r.allowed_categories || r.allowed_categories?.includes(response?.additionalCategory?.trim())) {
                                return ControleDeAcessoService.registerParticipant({
                                    rentpass_participant: response?.id,
                                    room: r?.id,
                                    participant_category: response?.additionalCategory,
                                    group: r?.group
                                });
                            }
                        })
                        .filter(Boolean);
        
                        const responses = await Promise.all(registrationPromises);
                    }
                } catch (error) {
                    toast.error('Houve um erro ao cadastrar o participante nas salas')
                    console.error('Error handling registration:', error);
                }
            }

            setShowConfirmModal(true);
        } catch (e: any) {
            toast.error(e.response.data.message ?? 'Erro desconhecido');
        } finally {
            setIsLoading(false);
        }
    };

    const handleCheckIn = async (participant: GetParticipantResponse) => {
        try {
            setIsLoading(true);

            if (!currentEvent) {
                throw new Error('Evento não configurado');
            }

            const d = new Date();
            d.setHours(d.getHours() - 3);
            await ApiService.checkInParticipant(participant.id, d.toISOString(), 0);

            toast.success('Checkin realizado com sucesso!');
        } catch (e: any) {
            if(e.response?.data?.message.includes('CheckIn já realizado para o participante')) {
                toast.error('Check-in já realizado');    
            }
            else {
                toast.error(e.message);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Background>
            <ModalLoading isActive={isLoading} />
            <ConfirmModal
                show={showConfirmModal}
                onClose={() => {
                    setShowConfirmModal(false);
                    navigate(-1);
                }}
                onConfirm={() => {
                    if (!currentEvent || !currentParticipant) {
                        return;
                    }
                    handleCheckIn(currentParticipant)
                    handlePrintTicket(currentEvent, currentParticipant);
                    setShowConfirmModal(false);
                    navigate(-1);
                }}
            />
            <Header pageTitle='Edição de Participante' />
            <WhiteBackground>
                <ParticipantAddForm>
                    <Form onSubmit={handleSubmit(handleSubmitForm)}>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                {currentEvent?.requiredFields?.includes('nome')? '*' : ''}
                                Nome
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control {...register('nome')} type='text' />
                                {errors.nome?.message && (
                                    <WarnText> {errors.nome.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                {currentEvent?.requiredFields?.includes('sobrenome')? '*' : ''}
                                Nome no crachá
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control {...register('sobrenome')} type='text' />
                                {errors.sobrenome?.message && (
                                    <WarnText> {errors.sobrenome.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                {currentEvent?.requiredFields?.includes('documento')? '*' : ''}
                                {currentEvent?.details === ''? 'Documento' : currentEvent?.details}
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control {...register('documento')} type='text' />
                                {errors.documento?.message && (
                                    <WarnText> {errors.documento.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                        {currentEvent?.requiredFields?.includes('estado') &&
                            <Form.Group as={Row} className='mb-3'>
                                <Form.Label column sm={2}>
                                    {currentEvent?.requiredFields?.includes('estado')? '*' : ''}
                                    Estado
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Select
                                        aria-label='Default select example'
                                        {...register('estado')}
                                    >
                                        <option value={''}> -- Selecione -- </option>
                                        {EstadosBrasileiros.map((item) => (
                                            <option key={item.sigla} value={item.sigla}>
                                                {' '}
                                                {item.sigla} - {item.nome}{' '}
                                            </option>
                                        ))}
                                    </Form.Select>

                                    {errors.estado?.message && (
                                        <WarnText> {errors.estado.message} </WarnText>
                                    )}
                                </Col>
                            </Form.Group>
                        }
                        {currentEvent?.requiredFields?.includes('cidade') &&
                            <Form.Group as={Row} className='mb-3'>
                                <Form.Label column sm={2}>
                                    {currentEvent?.requiredFields?.includes('cidade')? '*' : ''}
                                    Cidade
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control {...register('cidade')} type='text' />
                                    {errors.cidade?.message && (
                                        <WarnText> {errors.cidade.message} </WarnText>
                                    )}
                                </Col>
                            </Form.Group>
                        }
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                {currentEvent?.requiredFields?.includes('empresa')? '*' : ''}
                                Empresa
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control {...register('empresa')} type='text' />
                                {errors.empresa?.message && (
                                    <WarnText> {errors.empresa.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                        {currentEvent?.requiredFields?.includes('cargo') &&
                            <Form.Group as={Row} className='mb-3'>
                                <Form.Label column sm={2}>
                                    {currentEvent?.requiredFields?.includes('cargo')? '*' : ''}
                                    Cargo
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control {...register('cargo')} type='text' />
                                    {errors.cargo?.message && (
                                        <WarnText> {errors.cargo.message} </WarnText>
                                    )}
                                </Col>
                            </Form.Group>
                        }
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                {currentEvent?.requiredFields?.includes('telCelular')? '*' : ''}
                                Tel Celular
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control {...register('telCelular')} type='text' />
                                {errors.telCelular?.message && (
                                    <WarnText> {errors.telCelular.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                        {currentEvent?.requiredFields?.includes('telComercial') &&
                            <Form.Group as={Row} className='mb-3'>
                                <Form.Label column sm={2}>
                                    {currentEvent?.requiredFields?.includes('telComercial')? '*' : ''}   
                                    Tel Comercial
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control {...register('telComercial')} type='text' />
                                    {errors.telComercial?.message && (
                                        <WarnText> {errors.telComercial.message} </WarnText>
                                    )}
                                </Col>
                            </Form.Group>
                        }
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                {currentEvent?.requiredFields?.includes('email')? '*' : ''}
                                E-mail
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control {...register('email')} type='text' />
                                {errors.email?.message && (
                                    <WarnText> {errors.email.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                *Categoria
                            </Form.Label>
                            <Col sm={10}>
                                <ButtonGroup className='mb-2'>
                                    {particitantCategories?.map((item, idx) => 
                                        <ToggleButton
                                            key={idx}
                                            id={`radio-${idx}`}
                                            type="radio"
                                            variant={'outline-secondary'}
                                            name="radio"
                                            value={item.id}
                                            checked={watch('categoria') === item.id}
                                            onChange={(e) => setValue('categoria',Number(e.currentTarget.value))}
                                        >
                                            {item.categoryName}
                                        </ToggleButton>
                                    )}
                                </ButtonGroup>
                                {errors.categoria?.message && (
                                    <WarnText> {errors.categoria.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                        {currentEvent?.additionalCategories &&
                            <Form.Group as={Row} className='mb-3'>
                                <Form.Label column sm={2}>
                                    *Categoria adicional
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Select
                                        aria-label='Default select example'
                                        {...register('additionalCategory')}
                                    >
                                        <option key={0} value={0}>--Selecione--</option>
                                        {currentEvent?.additionalCategories?.split(',').map((item) => (
                                            <option key={item} value={item}>
                                                {' '}
                                                {item}
                                            </option>
                                        ))}
                                    </Form.Select>

                                    {errors.additionalCategory?.message && (
                                        <WarnText> {errors.additionalCategory.message} </WarnText>
                                    )}
                                </Col>
                            </Form.Group>
                        }
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                Observações
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control {...register('obs')} type='text' />
                                {errors.email?.message && (
                                    <WarnText> {errors.email.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>

                        {/*currentEvent?.surveyControl && <>
                            <Form.Group as={Row} className='mb-3'>
                                <Form.Label column sm={2}>
                                    *Tipo
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Select
                                        aria-label='Default select example'
                                        {...register('additionalCategory')}
                                    >
                                        <option key={0} value={0}>--Selecione--</option>
                                        {categoriesResponseOptions.map((item, index) => (
                                            <option key={index} value={item}>
                                                {' '}
                                                {item}
                                            </option>
                                        ))}
                                    </Form.Select>

                                    {errors.additionalCategory?.message && (
                                        <WarnText> {errors.additionalCategory.message} </WarnText>
                                    )}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className='mb-3'>
                                <Form.Label column sm={2}>
                                    *Vai participar do Mulheres de Fibra?
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Select
                                        aria-label='Default select example'
                                        {...register('questionResponse')}
                                    >
                                        <option key={0} value={0}>--Selecione--</option>
                                        {questionResponseOptions.map((item, index) => (
                                            <option key={index} value={item}>
                                                {' '}
                                                {item}
                                            </option>
                                        ))}
                                    </Form.Select>

                                    {errors.categoria?.message && (
                                        <WarnText> {errors.categoria.message} </WarnText>
                                    )}
                                </Col>
                            </Form.Group>
                                    </>*/}



                        <DashedHR />

                        {/*Todos os usuários podem inativar o participante a partir de 31/01 */}
                            <Form.Group as={Row} className='mb-3'>
                                <Col sm={{ span: 10, offset: 2 }}>
                                    <Form.Check
                                        type={'checkbox'}
                                        id={`Inativo-${'checkbox'}`}
                                        label={`Inativo`}
                                        {...register('ativo')}
                                    />
                                </Col>
                            </Form.Group>
                        <SubmitButton>
                            <TButton type='submit'> Gravar </TButton>
                        </SubmitButton>
                    </Form>
                </ParticipantAddForm>
            </WhiteBackground>
        </Background>
    );
}
